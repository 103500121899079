import axios from "axios";

export class UrlWebinarList extends HTMLElement {
  constructor() {
    super();
    // ShadowDOM
    const shadow = this.attachShadow({ mode: 'open' });
  }

  static get observedAttributes() { return ['webinars']; }

  get webinars() {
    return JSON.parse(this.getAttribute('webinars'));
  }

  set webinars(v) {
    this.setAttribute('webinars', JSON.stringify(v));
  }

  async loadWebinars() {
    if (sessionStorage.getItem('conferences') !== null) {
      this.webinars = JSON.parse(sessionStorage.getItem('conferences'));
      return this.webinars;
    }

    try {
      const resp = await axios.get('/bigmarker/api/events');

      if (resp.data) {
        this.webinars = resp.data.conferences;
        window.sessionStorage.setItem('conferences', JSON.stringify(this.webinars));
      }
    }
    catch (err) {
      console.error(err);
    }

    return this.webinars;
  }

  attributeChangedCallback(attrName, oldVal, newVal) {
    this.render();
  }

  render() {
    let wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'url-events-box');
    let div = document.createElement('div');
    div.classList.add('grid-container-3');

    this.webinars.forEach(element => {
      if (element.privacy !== 'private') {
        let card = document.createElement('div');
        card.classList.add('url-card-outline');
        let start = element.start_time;
        let startd = new Date(start);
        let formatStart = startd.toLocaleString('en-US', {
          weekday: 'short',
          day: 'numeric',
          year: 'numeric',
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        });

        card.innerHTML = `
          <img src="${element.background_image_url}" class="card-img-top img-fluid" alt="People attending a webinar.">
          <div class="card-body">
            <h5 class="card-title">${element.title}</h5>
            <h6 class="card-text mb-3">${formatStart}</h6>
            <p class="card-text mb-4">${element.purpose}</p>
          </div>
          <div class="card-footer w-100">
            <p class="card-text text-center"><a class="btn btn-primary" href="${element.conference_address}">Register</a></p>
          </div>
        `;
        div.appendChild(card);
      }
    });

    wrapper.appendChild(div);

    const style = document.createElement('style');
    style.textContent = `
      .grid-container-3 {
        display: grid;
        gap: 1.5rem 2rem;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-evenly;
      }

      .url-card {
        color: #a5d7f4;
      }

      .url-card .card-text a {
        text-decoration: underline;
      }

      .url-card .card-title {
        font-weight: 600;
      }

      .url-card p {
        margin-top: 0;
        margin-bottom: 0;
      }

      .p2 {
        padding-left: 2.5rem !important;
      }

      .mb-3 {
        margin-bottom: 1rem !important;
      }

      .mb-4 {
        margin-bottom: 1.5rem !important;
      }

      .w-100 {
        width: 100% !important;
      }

      .text-center {
        text-align: center !important;
      }

      .btn-primary {
        color: #fff;
        text-shadow: -1px 1px 0 grey, 1px 1px 0 grey, 1px -1px 0 grey, -1px -1px 0 grey;
      }

      h5.card-title {
        font-size: 1.25rem;
        font-weight: 600;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 0;
      }

      h6 {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.2;
        margin-top: 0;
      }

      .btn {
        --bs-btn-padding-x: .75rem;
        --bs-btn-padding-y: .375rem;
        --bs-btn-font-family: ;
        --bs-btn-font-size: 1rem;
        --bs-btn-font-weight: 400;
        --bs-btn-line-height: 1.5;
        --bs-btn-color: #333;
        --bs-btn-bg: transparent;
        --bs-btn-border-width: 1px;
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: .4rem;
        --bs-btn-hover-border-color: transparent;
        --bs-btn-box-shadow: inset 0 1px 0 rgba(254, 253, 251, .15), 0 1px 1px rgba(0, 0, 0, .075);
        --bs-btn-disabled-opacity: .65;
        --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
        display: inline-block;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        font-family: var(--bs-btn-font-family);
        font-size: var(--bs-btn-font-size);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        color: var(--bs-btn-color);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        border-radius: var(--bs-btn-border-radius);
        background-color: var(--bs-btn-bg);
        background-image: var(--bs-gradient);
        box-shadow: var(--bs-btn-box-shadow);
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }

      .btn-primary {
        --bs-btn-color: #fff;
        --bs-btn-bg: #eb9d02;
        --bs-btn-border-color: #eb9d02;
        --bs-btn-hover-color: rgb(0, 0, 0);
        --bs-btn-hover-bg: #eeac28;
        --bs-btn-hover-border-color: #eda71b;
        --bs-btn-focus-shadow-rgb: 200, 133, 2;
        --bs-btn-active-color: rgb(0, 0, 0);
        --bs-btn-active-bg: #efb135;
        --bs-btn-active-border-color: #eda71b;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        --bs-btn-disabled-color: rgb(0, 0, 0);
        --bs-btn-disabled-bg: #eb9d02;
        --bs-btn-disabled-border-color: #eb9d02;
      }

      .btn-primary:hover,
      .btn-primary:active {
        color: #98d0ee;
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color)
        font-weight: 300;
      }

      .url-card-outline {
        color: #a5d7f4;
        border-radius: .5rem;
        border: 1px solid #a5d7f4;
        position: relative;
        display: flex;
        flex-wrap: wrap;
      }

      @media (max-width: 768px) {
        .grid-container-3 {
          display: block;
        }

        .url-card {
          text-align: center;
          margin-bottom: 2rem;
        }

        .url-card-outline {
          text-align: center;
          margin-bottom: 2rem;
        }
      }

      .url-card-outline img {
        margin-bottom: 1rem;
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .url-card-outline .card-body,
      .url-card-outline .card-footer {
        padding-left: 1rem;
        padding-right: 1rem;
        align-self: flex-end;
      }

      .url-card-outline .card-footer a.btn.btn-primary {
        display: flex;
        justify-content: center;
        text-decoration: none;
      }

      .url-card-outline .card-img-top {
        border-top-right-radius: .5rem;
        border-top-left-radius: .5rem;
      }

      .url-card-outline .card-title {
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .url-card-outline p.card-text {
        margin-top: 0;
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

    `;

    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(wrapper);
  }

  async connectedCallback() {
    await this.loadWebinars();
  }
}

customElements.define('url-webinar-list', UrlWebinarList);
