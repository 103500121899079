import axios from 'axios';
import { CMBUtil } from './cmbutil';

export class UrlProfileInfo extends HTMLElement {
  constructor() {
      super();
      // ShadowDOM
      const shadow = this.attachShadow({ mode: 'open' });
      // Container
      const wrapper = document.createElement('div');
      wrapper.setAttribute('class', 'profile-box');

      const h3 = document.createElement('h3');
      h3.setAttribute('class', 'text-heading-blue');
      h3.textContent = 'Profile Information';

      const dl = document.createElement('dl');
      dl.setAttribute('class', 'text-p-white');

      const dt1 = document.createElement('dt');
      dt1.textContent = 'Agent Name:';

      const dd1 = document.createElement('dd');
      dd1.textContent = this.getAttribute('agent_name');

      const dt2 = document.createElement('dt');
      dt2.textContent = 'URL Agent Id:';
      const dd2 = document.createElement('dd');
      dd2.textContent = this.getAttribute('url_agentnum');

      const dt3 = document.createElement('dt');
      dt3.textContent = 'Agency Name:';
      const dd3 = document.createElement('dd');
      dd3.textContent = this.getAttribute('agency_name');

      const dt4 = document.createElement('dt');
      dt4.textContent = 'URL Agency Id:';
      const dd4 = document.createElement('dd');
      dd4.textContent = this.getAttribute('url_agencynum');

      const dt5 = document.createElement('dt');
      dt5.textContent = 'Email:';
      const dd5 = document.createElement('dd');
      dd5.textContent = this.getAttribute('email');

      // Append dl/dt nodes
      dl.append(dt1, dd1, dt2, dd2, dt3, dd3, dt4, dd4, dt5, dd5);

      const style = document.createElement('style');
      console.log(style.isConnected);
      style.textContent = `
          .text-heading-blue {
              color: rgb(118, 175, 211);
              font-size: 1.575rem;
              margin-bottom: 0.5rem;
              margin-top: 0;
              font-weight: 500;
              line-height: 1.2;
              font-family: Helvetica,Arial,FreeSans,sans-serif;
          }

          dl {
              margin-top: 0;
              margin-bottom: 1rem;
          }

          dt {
              font-weight: 700;
          }

          dd {
              margin-bottom: 0.5rem;
              margin-left: 0;
          }

          .text-p-white {
              color: rgb(212, 238, 255);
          }
      `;

      // Append everything to shadow->wrapper
      shadow.appendChild(style);
      console.log(style.isConnected);
      shadow.appendChild(wrapper);
      wrapper.appendChild(h3);
      wrapper.appendChild(dl);
  }
}

customElements.define('url-profile-info', UrlProfileInfo);
