import axios from 'axios';

export class UrlDebitBalances extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  static get observedAttributes() { return ['balances']; }

  get balances() {
    return JSON.parse(this.getAttribute('balances'));
  }

  set balances(v) {
    this.setAttribute('balances', JSON.stringify(v));
  }

  async loadDebitBalances(uid) {
    try {
      const resp = await axios.get(`/api/debit/${uid}`);

      if (resp.data) {
        this.balances = resp.data;
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  attributeChangedCallback(attrName, oldVal, newVal) {
    this.render();
  }

  render() {
    const wrapper = document.createElement('div');
    wrapper.classList.add('debit-balance-wrapper');

    const h3 = document.createElement('h3');
    h3.classList.add('text-heading-blue');
    h3.textContent = 'Debit Balances';
    wrapper.appendChild(h3);

    const alert = document.createElement('div');
    alert.setAttribute('role', 'alert');
    alert.classList.add('alert', 'url-blue-bg');

    const dl = document.createElement('dl');
    let ii = 0;

    this.balances.forEach(el => {
      if (el.debit_balance_end > 0) {
        const dt = document.createElement('dt');
        const dd = document.createElement('dd');
        dt.textContent = `${el.carrier}:`;
        dd.textContent = `$${el.debit_balance_end}`;

        dl.append(dt, dd);
        ii++;
      }
    });

    if (ii === 0) {
      let dt = document.createElement('dt');
      dt.textContent = `No debit balances at this time`;
      dl.append(dt);
    }

    alert.appendChild(dl);
    wrapper.appendChild(alert);

    const style = document.createElement('style');
    style.textContent = `
      .url-blue-bg {
        background-color: #114672 !important;
      }
      .alert {
        --bs-alert-bg: transparent;
        --bs-alert-padding-x: 1rem;
        --bs-alert-padding-y: 1rem;
        --bs-alert-margin-bottom: 1rem;
        --bs-alert-color: inherit;
        --bs-alert-border-color: transparent;
        --bs-alert-border: 1px solid var(--bs-alert-border-color);
        --bs-alert-border-radius: .4rem;
        position: relative;
        padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
        margin-bottom: var(--bs-alert-margin-bottom);
        color: var(--bs-alert-color);
        background-color: var(--bs-alert-bg);
        border: var(--bs-alert-border);
        border-radius: var(--bs-alert-border-radius);
      }
      .text-heading-blue {
          color: rgb(118, 175, 211);
          font-size: 1.575rem;
          margin-bottom: 0.5rem;
          margin-top: 0;
          font-weight: 500;
          line-height: 1.2;
          font-family: Helvetica,Arial,FreeSans,sans-serif;
      }

      dl {
          margin-top: 0;
          margin-bottom: 1rem;
      }

      dt {
          font-weight: 400;
      }

      dd {
          margin-bottom: 0.5rem;
          margin-left: 0;
      }

      .text-p-white {
          color: rgb(212, 238, 255);
      }
    `;

    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(wrapper);
  }

  async connectedCallback() {
    const url_agentnum  = this.getAttribute('url_agentnum');
    await this.loadDebitBalances(url_agentnum);
  }
}

customElements.define('url-debit-balances', UrlDebitBalances);
