import { CMBUtil } from './cmbutil';
import axios from 'axios';

export class UrlCarrierSelect extends HTMLElement {
  constructor() {
      super();
      // ShadowDOM
      const shadow = this.attachShadow({ mode: 'open' });
  }

  static get observedAttributes() { return ['comm_carrier_codes']; }

  get comm_carrier_codes() {
    return JSON.parse(this.getAttribute('comm_carrier_codes'));
  }

  set comm_carrier_codes(v) {
    this.setAttribute('comm_carrier_codes', JSON.stringify(v));
  }

  async getCarriers() {
    try {
      const cc = await axios.get('/commcarriercodes');

      if (cc.data) {
        this.comm_carrier_codes = cc.data;
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  async connectedCallback() {
    await this.getCarriers();
  }

  attributeChangedCallback(attrName, oldVal, newVal) {
    this.render();
  }

  render() {
    // Container
    const select = document.createElement('select');
    select.classList.add('form-select');
    select.setAttribute('name', 'carrier_code');
    select.setAttribute('id', 'carrier_code');

    const optDefault = document.createElement('option');
    optDefault.textContent = 'Choose a carrier';
    select.append(optDefault);

    this.comm_carrier_codes.forEach((item) => {
      const opts = document.createElement('option');
      opts.setAttribute('value', `${item.carrier_code}`);
      opts.textContent = `${item.carrier}`;
      select.append(opts);
    });

    const style = document.createElement('style');
    style.textContent = `
        .form-label {
          margin-bottom: .5rem;
          display: inline-block;
        }
        .form-select {
          display: block;
          width: 100%;
          padding: .375rem 2.25rem .375rem .75rem;
          -moz-padding-start: calc(0.75rem - 3px);
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right .75rem center;
          background-size: 16px 12px;
          border: 1px solid #ced4da;
          border-radius: .25rem;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
      }
    `;

    this.shadowRoot.appendChild(style);
    this.shadowRoot.appendChild(select);

    this.carrierSelect = this.shadowRoot.querySelector('#carrier_code');
    this.carrierSelect.addEventListener('change', (evt) => {
      let carrier_code = this.carrierSelect.value;

      console.log(`Value ${carrier_code}`);

      // I have to use getRootNode() to break out of the shadowDom.
      let container = this.getRootNode();
      let hidden = container.querySelector('#hcarrier_code');
      hidden.value = carrier_code;
    });
  }
}

customElements.define('url-carrier-select', UrlCarrierSelect);
