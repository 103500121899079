import axios from "axios";

export class UrlSearchPolicy extends HTMLElement {
  constructor() {
      super();
      // ShadowDOM
      const shadow = this.attachShadow({ mode: 'open' });
      // Container
      const wrapper = document.createElement('form');
      wrapper.id = 'url-search-policy';
      wrapper.setAttribute('novalidate', 'novalidate');

      const inputWrapper = document.createElement('div');
      inputWrapper.classList.add('p-1');
      inputWrapper.innerHTML = `
        <label for="policy_number" class="form-label">Policy number</label>
        <input type="text" name="policy_number" id="policy_number" placeholder="policy number" class="form-control" required>
      `;

      wrapper.appendChild(inputWrapper);

      const btnWrapper = document.createElement('div');
      btnWrapper.classList.add('p-1');

      const button = document.createElement('input');
      button.classList.add('btn', 'btn-primary', 'mb-3');
      button.setAttribute('id', 'submit');
      button.setAttribute('type', 'submit');
      button.value = 'Search';

      btnWrapper.appendChild(button);

      const resultTitleRow = document.createElement('div');
      resultTitleRow.classList.add('p-1');

      const resultTitleCol = document.createElement('div');
      resultTitleCol.classList.add('col-12');
      resultTitleCol.classList.add('url-policy-result-title');
      resultTitleRow.appendChild(resultTitleCol);

      const resultRow = document.createElement('div');
      resultRow.classList.add('url-policy-result');
      resultTitleRow.appendChild(resultRow);

      wrapper.appendChild(btnWrapper);
      wrapper.appendChild(resultTitleRow);

      const style = document.createElement('style');
      style.textContent = `
        .row {
          --bs-gutter-x: 1.5rem;
          --bs-gutter-y: 0;
          display: flex;
          flex-wrap: wrap;
          margin-top: calc(-1 * var(--bs-gutter-y));
          margin-right: calc(-.5 * var(--bs-gutter-x));
          margin-left: calc(-.5 * var(--bs-gutter-x));
        }
        .col-12 {
          flex: 0 0 auto;
          width: 100%;
        }
        .p-1 {
          padding: .25rem !important;
        }

        label {
          display: inline-block;
        }

        .form-label {
          margin-bottom: 0.5rem;
        }

        .form-control {
          display: block;
          width: 100%;
          padding: .375rem .75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #333;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          appearance: none;
          border-radius: .4rem;
          box-shadow: inset 0 1px 2px #00000013;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        input#policy_number {
          margin: 0;
          font-family: inherit;

        }
        .btn {
          margin-bottom: 1rem !important;
        }

        .btn {
          --bs-btn-padding-x: .75rem;
          --bs-btn-padding-y: .375rem;
          --bs-btn-font-family: ;
          --bs-btn-font-size: 1rem;
          --bs-btn-font-weight: 400;
          --bs-btn-line-height: 1.5;
          --bs-btn-color: #333;
          --bs-btn-bg: transparent;
          --bs-btn-border-width: 1px;
          --bs-btn-border-color: transparent;
          --bs-btn-border-radius: .4rem;
          --bs-btn-box-shadow: inset 0 1px 0 rgba(254, 253, 251, .15), 0 1px 1px rgba(0, 0, 0, .075);
          --bs-btn-disabled-opacity: .65;
          --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
          display: inline-block;
          padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
          font-family: var(--bs-btn-font-family);
          font-size: var(--bs-btn-font-size);
          font-weight: var(--bs-btn-font-weight);
          line-height: var(--bs-btn-line-height);
          color: var(--bs-btn-color);
          text-align: center;
          text-decoration: none;
          vertical-align: middle;
          cursor: pointer;
          user-select: none;
          border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
          border-radius: var(--bs-btn-border-radius);
          background-color: var(--bs-btn-bg);
          background-image: var(--bs-gradient);
          box-shadow: var(--bs-btn-box-shadow);
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .btn-primary {
          --bs-btn-color: rgb(0, 0, 0);
          --bs-btn-bg: rgb(255, 178, 64);
          --bs-btn-border-color: rgb(255, 178, 64);
          --bs-btn-hover-color: rgb(0, 0, 0);
          --bs-btn-hover-bg: #ffbe5d;
          --bs-btn-hover-border-color: #ffba53;
          --bs-btn-focus-shadow-rgb: 217, 151, 54;
          --bs-btn-active-color: rgb(0, 0, 0);
          --bs-btn-active-bg: #ffc166;
          --bs-btn-active-border-color: #ffba53;
          --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
          --bs-btn-disabled-color: rgb(0, 0, 0);
          --bs-btn-disabled-bg: rgb(255, 178, 64);
          --bs-btn-disabled-border-color: rgb(255, 178, 64);
        }

        .url-policy-result-title {
          color: #fefdfb;
        }

        a.url-search-res-link {
          color: #c4e4f5
        }
      `;

      shadow.appendChild(style);
      shadow.appendChild(wrapper);

      this.searchForm = this.shadowRoot.querySelector('#url-search-policy');
      this.inputQuery = this.shadowRoot.querySelector('#policy_number');
      this.searchButton = this.shadowRoot.querySelector('#submit');
      this.titleRow = this.shadowRoot.querySelector('.url-policy-result-title');
      this.resultRow = this.shadowRoot.querySelector('.url-policy-result');

      this.searchForm.addEventListener('submit', (evt) => {
        evt.preventDefault();
      });

      this.searchButton.addEventListener('click', async (evt) => {
          evt.preventDefault();

          try {
            const resp = await axios.get(`/sforce/policysearch/${this.inputQuery.value}`);

            this.titleRow.innerHTML = `Searching for <em>${this.inputQuery.value}</em>... <hr>`;
            this.resultRow.innerHTML = '';

            if(resp.data) {
              resp.data.searchRecords.forEach(item => {
                let p = document.createElement('p');
                p.innerHTML = `<a class="url-search-res-link" href="https://urlinsgroup.lightning.force.com/${item.Id}">${item.Name}</a>`;
                this.resultRow.appendChild(p);
              });
            }
            console.log('RESPONSE', resp.data);
          }
          catch (error) {
            console.log(error);
          }
      });
  }
}

customElements.define('url-search-policy', UrlSearchPolicy);
