import { CMBUtil } from './cmbutil';

export class UrlYearSelect extends HTMLElement {
  constructor() {
      super();
      // ShadowDOM
      const shadow = this.attachShadow({ mode: 'open' });
      this.setAttribute('name', 'year');
      // Container
      const select = document.createElement('select');
      select.classList.add('form-select');
      select.setAttribute('name', 'year');
      select.setAttribute('id', 'year');

      const optDefault = document.createElement('option');
      optDefault.setAttribute('selected', 'selected');
      optDefault.textContent = 'Select a year';

      select.appendChild(optDefault);

      const current = new Date().getFullYear();
      const base = (current - 4);

      for (let i = base; i <= current; i++) {
        const opt = document.createElement('option');
        opt.setAttribute('value', `${i}`);
        opt.textContent = `${i}`;
        select.appendChild(opt);
      }

      const style = document.createElement('style');
      style.textContent = `
          .form-label {
            margin-bottom: .5rem;
            display: inline-block;
          }
          .form-select {
            display: block;
            width: 100%;
            padding: .375rem 2.25rem .375rem .75rem;
            -moz-padding-start: calc(0.75rem - 3px);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
      `;

      shadow.appendChild(style);
      shadow.appendChild(select);

      this.yearSelect = this.shadowRoot.querySelector('select');
      this.yearSelect.addEventListener('change', (evt) => {
        evt.stopPropagation();
        let year = parseFloat(this.yearSelect.value);

        console.log(`Value ${year}`);

        // I have to use getRootNode() to break out of the shadowDom.
        let container = this.getRootNode();
        let hidden = container.querySelector('#hyear');
        hidden.value = year;
      });
  }
}

customElements.define('url-year-select', UrlYearSelect);
