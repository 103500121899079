export class UrlHowCta extends HTMLElement {
    /**
     * Params: href URL,
     *         img-src URL,
     *         card-title text,
     *         card-text text,
     *         card-title-secondary text
     */
    constructor() {
        super();
        // ShadowDOM
        const shadow = this.attachShadow({ mode: 'open' });
        // Container row
        const wrapper = document.createElement('div');
        wrapper.classList.add('col-md-3');
        wrapper.classList.add('url-cta-container');
        wrapper.classList.add('my-4');

        // Wrap everything in anchor
        const href = document.createElement('a');
        href.setAttribute('href', this.getAttribute('href'));
        href.classList.add('url-link-wrapper');

        // Layer 1
        const div1 = document.createElement('div');
        div1.classList.add('card', 'url-cta-grey', 'url-cta-init');

        const imgDiv = document.createElement('div');
        imgDiv.classList.add('card-img-top', 'd-flex', 'justify-content-center');

        const img = document.createElement('img');
        img.setAttribute('src', this.getAttribute('img-src'));
        img.classList.add('card-img-top');

        imgDiv.appendChild(img);

        const cbody = document.createElement('div');
        cbody.classList.add('card-body');

        const h5 = document.createElement('h5');
        h5.classList.add('card-title');
        h5.innerHTML = `${this.getAttribute('card-title')} <span class="cta-right-arrow">▸</span>`;

        const p = document.createElement('p');
        p.classList.add('card-text');
        p.innerHTML = this.getAttribute('card-text');

        const footerBar = document.createElement('div');
        footerBar.classList.add('cta-footer-bar');
        footerBar.innerHTML = '&nbsp;';

        cbody.append(h5, p, footerBar);

        div1.append(imgDiv, cbody);

        // Second Layer
        const div2 = document.createElement('div');
        div2.classList.add('card', 'text-bg-secondary', 'url-cta-layer', 'd-none');

        const cbody2 = document.createElement('div');
        cbody2.classList.add('card-body');

        const h4 = document.createElement('h4');
        h4.classList.add('card-title');
        h4.textContent = this.getAttribute('card-title-secondary');

        cbody2.appendChild(h4);
        div2.appendChild(cbody2);

        href.append(div1, div2);

        wrapper.appendChild(href);

        // Styles
        const style = document.createElement('style');
        console.log(style.isConnected);
        style.textContent = `
            html * { font-family: 'Roboto Flex', sans-serif; }

            .url-cta-container {
              position: relative;
            }

            .d-none { display: none !important; }

            .my-4 {
              margin-top: 1.5rem !important;
              margin-bottom: 1.5rem !important;
            }

            .col-md-3 {
              flex: 0 0 auto;
              width: 25%;
            }

            .row>* {
              max-width: 100%;
              padding-right: calc(1.5rem * .5);
              padding-left: calc(1.5rem * .5);
            }

            a.url-link-wrapper,
            a.url-link-wrapper:hover,
            a.url-link-wrapper:active,
            a.url-link-wrapper:visited {
              text-decoration: none !important;
              color: #fefdfb!important;
            }

            .url-cta-init {
              border-radius: 0%;
              padding-top: 1rem;
              min-height: 18.125rem;
            }

            .url-cta-grey {
              background: #51657cb3;
            }

            .url-cta-init img {
              width: 133px;
              height: auto;
            }

            .url-cta-layer {
              border-radius: 0%;
              border: 4px solid #eb9d02;
              height: 100%;
              text-align: center;
              min-height: 18.125rem;
            }

            .url-cta-layer .card-body {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .cta-right-arrow {
              color: #eb9d02;
              vertical-align: text-bottom;
            }

            .cta-footer-bar {
              background-color: #eb9d02;
              height: 4px;
              width: 100%;
              display: block;
            }

            .card {
              position: relative;
              display: flex;
              flex-direction: column;
              word-wrap: break-word;
          }

          .card-img, .card-img-top {
            border-top-left-radius: calc(.4rem - 1px);
            border-top-right-radius: calc(.4rem - 1px);
            width: 100%;
          }

          .card-body {
            flex: 1 1 auto;
            padding: 1rem;
          }

          h5.card-title {
            margin-bottom: .5rem;
            font-size: 1.25rem;
            margin-top: 0;
            font-weight: 500;
            line-height: 1.2;
          }

          p {
            margin-top: 0;
            margin-bottom: 1rem;
          }

          h4.card-title {
            font-size: 1.5rem;
            margin-bottom: .5rem;
          }
        `;

        // Append everything to shadow->wrapper
        shadow.appendChild(style);
        console.log(style.isConnected);
        shadow.appendChild(wrapper);

        // Wire up events using shadowRoot
        this.container = this.shadowRoot.querySelector('.url-cta-container');
        this.cardInit = this.shadowRoot.querySelector('.url-cta-init');
        this.cardLayer = this.shadowRoot.querySelector('.url-cta-layer');
        let maxHeight = this.cardInit.offsetHeight;
        this.cardLayer.style.height = maxHeight + 'px';

        this.container.addEventListener('mouseover', (evt) => {
          if (this.cardInit && this.cardLayer) {
            this.cardInit.classList.add('d-none');
            this.cardLayer.classList.remove('d-none');
          }
        });

        this.container.addEventListener('mouseout', (evt) => {
          this.cardInit.classList.remove('d-none');
          this.cardLayer.classList.add('d-none');
        });
    }
}

customElements.define('url-how-cta', UrlHowCta);
