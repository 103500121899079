import '../scss/main.scss';
import 'gridjs/dist/theme/mermaid.css';
import * as bootstrap from 'bootstrap';
import { CMBUtil } from './cmbutil';
import { UrlHowCta } from './url-how-cta';
import { UrlSearch } from './url-search';
import { UrlPhotobar } from './url-photobar';
import { UrlSearchPolicy } from './url-search-policy';
import { UrlProfileInfo } from './url-profile-info';
import { UrlDebitBalances } from './url-debit-balances';
import { UrlMonthSelect } from './url-month-select';
import { UrlCarrierSelect } from './url-carrier-select';
import { UrlYearSelect } from './url-year-select';
import { UrlWebinarList } from './url-webinar-list';
import { Grid, h } from 'gridjs';
import axios from 'axios';

CMBUtil.loaded(async (evt) => {
    stickyHeader();

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.statements-box'))) {
      loadPortal();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.grid-wrapper'))) {
      loadContracting();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.cases-grid-wrapper'))) {
      loadCases();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.impersonate-agent'))) {
      loadImpersonate();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.datafeed-grid-wrapper'))) {
      loadDatafeedGrid(month, year, special_id, carrier_code);
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('#orion-grid-wrapper'))) {
      loadOrionGrid();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('#register-form'))) {
      registerLoad();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.url-how-cta'))) {
      console.log('Loading CTA Events');
      loadCTAEvents();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.is-agent'))) {
      checkAgent();
    }

    if (!CMBUtil.isNullOrEmpty(document.querySelector('.format-name'))) {
      formatName2();
    }

    if(!CMBUtil.isNullOrEmpty(document.querySelector('.url-cta-container'))) {
      ctaInit();
    }
});

CMBUtil.resize((evt) => {
    stickyHeader();
});

function ctaInit() {
  const containers = document.querySelectorAll('.url-cta-container');
  const cards = document.querySelectorAll('.url-cta-init');
  let maxHeight = 0;

  for (let i = 0; i < cards.length; i++) {
    if (cards[i].offsetHeight > maxHeight) {
      maxHeight = cards[i].offsetHeight;
    }
  }

  cards.forEach((card) => card.style.height = `${maxHeight}px`);

  containers.forEach((elem, key) => {
    const cardInit = elem.querySelector('.url-cta-init');
    const height = cardInit.offsetHeight;
    const cardLayer = elem.querySelector('.url-cta-layer');
    cardLayer.style.height = height + 'px';

    elem.addEventListener('mouseover', (evt) => {
      if (!CMBUtil.isNullOrEmpty(cardInit) && !CMBUtil.isNullOrEmpty(cardLayer)) {
        cardInit.classList.add('d-none');
        cardLayer.classList.remove('d-none');
      }
    });

    elem.addEventListener('mouseout', (evt) => {
      cardInit.classList.remove('d-none');
      cardLayer.classList.add('d-none');
    });
  });
}

function formatName2() {
  const name = document.getElementById('agent_name');
  const btn = document.getElementById('ml-submit');
  const loading = document.querySelector('#loading');

  btn.addEventListener('click', async (evt) => {
    evt.preventDefault();
    if (name.value !== '') {
      const form = document.querySelector('form.format-name');
      loading.style.display = 'block';
      let formData = new FormData(form);
      let html = document.getElementById('result');
      html.innerHTML = '';

      let respi = await axios.post('/api/nameapi', formData);

      if (respi.data) {
        loading.style.display = 'none';

        console.log(respi.data.bestMatch);

        if (!CMBUtil.isNullOrEmpty(respi.data.bestMatch)) {
          let tmpNAme = respi.data.bestMatch.parsedPerson.outputPersonName.terms;
          let first = '';
          let last = '';
          let middle = '';
          let suffix = '';

          console.log('tmpNAme ', tmpNAme);

          tmpNAme.forEach(el => {
            switch (el.termType) {
              case 'SURNAME':
                last = el.string;
                break;
              case 'GIVENNAME':
                first = el.string;
                break;
              case 'GIVENNAMEINITIAL':
                middle = el.string;
                break;
              case 'QUALIFIER':
                suffix = el.string;
                break;
              default:
                break;
            }
          });

          html.innerHTML = `${first} ${middle} ${last} ${suffix}`.trim();
        }
      }
      else {
        html.innerHTML = `Issue communicating with server`;
      }
    }
  });
}

function formatName() {
  const agent_name = document.querySelector('#agent_name');
  const btn = document.querySelector('#ml-submit');
  const loading = document.querySelector('#loading');

  btn.addEventListener('click', async (evt) => {
    evt.preventDefault();
    const form = document.querySelector('form.format-name');
    loading.style.display = 'block';
    let formData = new FormData(form);
    let html = document.getElementById('result');
    html.innerHTML = '';

    let respi = await axios.post('/tensorflow/nameformat', formData);
    if (respi.data) {
      loading.style.display = 'none';

      console.log('res.data:', respi.data);

      if (!CMBUtil.isNullOrEmpty(respi.data.agent_name)) {
        let tmpName = respi.data.agent_name;
        html.innerHTML = `Formatted name: ${tmpName}`;
      }
      else {
        html.innerHTML = `Response from server was empty.`;
      }
    }
    else {
      html.innerHTML = `There was a problem contacting the server.`;
    }
  });

}

function checkAgent() {
  const agent_name = document.getElementById('agent_name');
  const form = document.querySelector('form.is-agent');
  const btn = document.getElementById('ml-submit');
  const loading = document.getElementById('loading');

  btn.addEventListener('click', async (evt) => {
    evt.preventDefault();
    loading.style.display = 'block';
    let formData = new FormData(form);
    let html = document.getElementById('result');
    html.innerHTML = '';
    let res = await axios.post('/tensorflow', formData);

    if (res.data) {
      loading.style.display = 'none';
      console.log('Button', res.data.message);
      if (res.data.message === 'true') {
        html.innerHTML = `${agent_name.value} is an agency`;
      }
      else {
        html.innerHTML = `${agent_name.value} is an agent`;
      }
    }
  });
}

function loadCTAEvents() {
  const ctas = document.querySelectorAll('.url-how-cta');

  if (ctas && ctas.length > 0) {
    ctas.forEach((node) => {
      node.addEventListener('mouseover', (evt) => {
        node.classList.add('layer');
        node.querySelector('.url-how-cta-overlay').classList.remove('closed');
        node.querySelector('.url-how-cta-layer-1').classList.add('closed');
      });

      node.addEventListener('mouseout', (evt) => {
        node.classList.remove('layer');
        node.querySelector('.url-how-cta-overlay').classList.add('closed');
        node.querySelector('.url-how-cta-layer-1').classList.remove('closed');
      });
    });
  }
}

function stickyHeader() {
    let wide = CMBUtil.viewportWidth();
    let header = document.querySelector('header');
    let footer = document.querySelector('footer.url-footer-mobile');

    if (wide >= 767 && header) {
        header.classList.remove('sticky-top');
    }
    else {
        if (!CMBUtil.isNullOrEmpty(header) && !header.classList.contains('sticky-top')) {
            header.classList.add('sticky-top');
        }
    }
}

async function registerLoad() {
  let form = document.querySelector('#register-form');
  let btn = document.getElementById('register-submit');

  form.addEventListener('submit', async (evt) => {
    evt.preventDefault();
    console.log('Form data submit');
  });

  btn.addEventListener('click', async (evt) => {
    evt.preventDefault();
    console.log('Button submit');
    let formData = new FormData(form);

    let resp = await axios.post('/login/register', formData);

    console.log('RESPONSE: ', resp.data);

    if (resp.data.message) {
      let alertBox = document.getElementById('alertMessageBox');
      alertBox.classList.remove('d-none');
      alertBox.classList.add('show');
      let alertMessage = document.getElementById('alertMessage');
      alertMessage.innerHTML = resp.data.message;
    }
    else if (resp.data.success) {
      window.location.href = '/users/register/success';
    }
  });
}

function loadGridTest(id, path) {
  let upath = `/sforce/agency/${id}/${path}`;
  new Grid({
    columns: [ 'Name', 'Carrier', 'Writing #', 'Start Date', 'Status', 'Notes To Agent' ],
    search: true,
    sort: true,
    server: {
      url: upath,
      then: data => data.map(contract => [
        contract.CustomerSigned.Name,
        contract.Account.Name,
        contract.Carrier_Writing_Agent_Number__c,
        contract.StartDate,
        contract.Status,
        contract.Notes_to_Agent__c
      ])
    },
    pagination: {
      enabled: true,
      limit: 20
    },
    className: {
      th: 'grid-header',
      td: 'grid-cell',
    }
  }).render(document.querySelector('.grid-wrapper'));
}

/** Impersonate URL Agent User */
async function loadImpersonate() {
  const urlagentText = document.querySelector('#urlagentnum');
  const form = document.querySelector('form.impersonate-agent');
  const impersonateBtn = document.querySelector('#impersonate-submit');

  form.addEventListener('submit', async (evt) => {
    evt.preventDefault();
    const url_agentnum = urlagentText.value;
    console.log('form submit ', url_agentnum);

    if(!CMBUtil.isNullOrEmpty(url_agentnum)) {
      localStorage.setItem('url_agentnum', url_agentnum);
      localStorage.removeItem('agent_info');
    }

    let agent_info = await storeAgentInfo(url_agentnum);
    window.location.replace(`/portal/${url_agentnum}`);
  });
}

/** MyURL Contracting page */
async function loadContracting() {
  let url_agent = getAgent();
  let agent_info = checkAgentAccess(url_agent);
  let id = '';
  let path = 'agents';

  if (CMBUtil.isNullOrEmpty(agent_info)) {
    agent_info = await storeAgentInfo(url_agent);
  }

  if (agent_info.is_principal) {
    id = agent_info.account_id;
    path = 'agency';
  }
  else {
    id = agent_info.contact_id;
    path = 'agents';
  }

  loadGridTest(id, path);
}

/** Main MyURL page */
async function loadPortal() {
  let url_agent = getAgent();
  let agent_info = checkAgentAccess(url_agent);

  if (CMBUtil.isNullOrEmpty(agent_info)) {
    agent_info = await storeAgentInfo(url_agent);
  }

  try {
    const resp = await axios.post('/api/statements/carriers', agent_info);
    const carrier_codes = resp.data;
    agent_info['carrier_codes'] = carrier_codes;
    const sresp = await axios.post('/api/statements', agent_info);
    const statements = sresp.data;
    createStatementSection(statements);
  }
  catch (err) {
    console.error(err);
  }
}

/** MyURL Cases page */
async function loadCases() {
  let url_agent = getAgent();
  let agent_info = checkAgentAccess(url_agent);

  if (CMBUtil.isNullOrEmpty(agent_info)) {
    agent_info = await storeAgentInfo(url_agent);
  }

  loadCasesGrid(agent_info);
}

function getAgent() {
  let url_agent = document.cookie
    .split('; ')
    .find((row) => row.startsWith('url_agentnum'))
    ?.split('=')[1];

  url_agent = url_agent || localStorage.getItem('url_agentnum');

  if(!CMBUtil.isNullOrEmpty(url_agent)) {
    localStorage.setItem('url_agentnum', url_agent);
  }

  return url_agent;
}

function checkAgentAccess(url_agent) {
  let agent_info = null;
  let store = localStorage.getItem('agent_info');

  if (!CMBUtil.isNullOrEmpty(store)) {
    agent_info = JSON.parse(store);
    let match = (agent_info.url_agentnum === url_agent) || (agent_info.url_agencynum === url_agent);

    // TODO: Throw error if not a match
    if (!match) {
      console.error('ERROR: url_agentnum or url_agencynum NOT A MATCH!');
      return 'ERROR: url_agentnum or url_agencynum NOT A MATCH!';
    }
  }

  return agent_info;
}

async function storeAgentInfo(url_agentnum) {
  if (CMBUtil.isNullOrEmpty(url_agentnum)) throw new Error(`storeAgentInfo requires url_agentnum argument.`);

  let agent_info = null;
  if (CMBUtil.isNullOrEmpty(agent_info)) {
    try {
      const agent = await axios.get(`/api/agent/${url_agentnum}`);
      agent_info = agent.data;

      localStorage.setItem('agent_info', JSON.stringify(agent.data));
    }
    catch (err) {
      console.error(err);
    }
  }
  return agent_info;
}

function createStatementSection(statements) {
  const wrapper = document.querySelector('.statements-box');
  statements = statements.sort((a, b) => a[0].carrier_code > b[0].carrier_code ? 1 : -1);
  statements.forEach(statement => {
    const monthName = CMBUtil.getMonthName(statement[0].month);
    const row = document.createElement('div');
    row.classList.add('row', 'my-3');

    const col = document.createElement('div');
    col.classList.add('col-md-8');
    col.innerHTML = `
      <h4 class="text-heading-blue">${statement[0].carrier}</h4>
      <h5 class="text-heading-blue">${statement[0].pagent_name}</h5>
      <ul class="list-unstyled text-p-white">
        <li>
          <a href="https://ref.urlinsgroup.com/agents/statements/pdf?month=${statement[0].month}&year=${statement[0].year}&special_id=${statement[0].special_id}&carrier_code=${statement[0].carrier_code}&url_agentnum=${statement[0].url_agentnum}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"/>
            </svg> ${monthName} Agent Statement (PDF)
          </a>
        </li>
        <li>
          <a href="https://ref.urlinsgroup.com/agents/statements/export?month=${statement[0].month}&year=${statement[0].year}&special_id=${statement[0].special_id}&carrier_code=${statement[0].carrier_code}&url_agentnum=${statement[0].url_agentnum}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table text-p-white" viewBox="0 0 16 16">
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
            </svg> ${monthName} Agent Statement (Excel)
          </a>
        </li>
        <li>
          <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
            </svg>
            Previous ${statement[0].carrier} Statements
          </a>
        </li>
      </ul>
    `;
    row.appendChild(col);
    wrapper.append(row);
  });
}

async function loadOrionGrid() {
  try {
    const resp = await axios.get('/sforce/orion/Health');
    const data = resp.data.records.map(opportunity => [
      opportunity.Id,
      opportunity.Name,
      opportunity.Carrier_Account__r.Name,
      opportunity.Policy_Number__c,
      opportunity.Alt_Policy_ID__c,
      opportunity.Agent_Contact__r.Agent_Number_ID__c,
      opportunity.Agent_Contact__r.Name,
      opportunity.Account.Name,
      opportunity.Plan_Name__c,
      (CMBUtil.isNullOrEmpty(opportunity.EAS_Agent_Name__c)) ? '' : opportunity.EAS_Agent_Name__r.Name,
      (CMBUtil.isNullOrEmpty(opportunity.EAS_Agent_Name__c)) ? '' : opportunity.EAS_Agent_Name__r.Agent_Number_ID__c,
      (CMBUtil.isNullOrEmpty(opportunity.EAS_Agent_Name__c)) ? '' : opportunity.EAS_Agent_Name__r.Gemini_agent__c,
      (CMBUtil.isNullOrEmpty(opportunity.Referring_Business__c)) ? '' : opportunity.Referring_Business__c.Name,
      (CMBUtil.isNullOrEmpty(opportunity.Referring_Business__c)) ? '' : opportunity.Referring_Business__r.ORGAGENCYID__c,
      (CMBUtil.isNullOrEmpty(opportunity.Referring_Business__c)) ? '' : opportunity.Referring_Business__r.Gemini_agency__c,
      opportunity.Division__c,
      opportunity.Orion_Flat_Referral_Fee_Amount__c,
      opportunity.Orion_payments_per_year__c,
      opportunity.RecordType.Name,
      opportunity.Type,
      opportunity.EffectiveDate__c,
      opportunity.Mode__c,
      opportunity.Last_Month_Number_of_Lives__c,
      opportunity.Most_Recent_Lives__c,
      opportunity.StageName,
      opportunity.CreatedDate,
      opportunity.URL_Employee_Referral_Fee__c,
      opportunity.Referral_Type__c,
      opportunity.Opportunity_Source__c,
      opportunity.Last_Paid_Invoice_Date__c,
      opportunity.All_Commission_Payments_Completed__c,
      opportunity.Notes__c,
      opportunity.Referral_Structure__c,
      opportunity.Current_Payment_Year__c,
      opportunity.Change_Over_date__c
    ]);

    const grid = new Grid({
      columns: [
        { name: 'Id', hidden: true },
        'Name',
        'Carrier',
        'Policy Number',
        'Alt Policy',
        'Writing Agent Id',
        'Writing Agent',
        'Client Name',
        'Plan',
        'Referral Agent',
        'Referral Agent Id',
        'Referral Agent Gemini',
        'Referral Agency',
        'Referral Agency Id',
        'Referral Agency Gemini',
        'Division',
        'Flat Fee',
        'Payments/Year',
        'Record Type',
        'Record Sub Type',
        'Effective Date',
        'Mode',
        'Last Month Lives',
        'Most Recent Lives',
        'Stage',
        'CreatedDate',
        'Employee Referral Fee',
        'Referral Type',
        'Opportunity Source',
        'Last Paid Invoice',
        'Payments Completed',
        'Notes',
        'Referral Structure',
        'Current Pay Year',
        'Changeover Date',
        'Opportunity Line Items'
      ],
      search: true,
      sort: true,
      data: data,
      pagination: {
        enabled: true,
        limit: 20
      },
      className: {
        th: 'grid-header',
        td: 'grid-cell',
      }
    }).render(document.querySelector('#orion-grid-wrapper'));

    grid.on('rowClick', (evt, row) => window.open(`https://urlinsgroup.lightning.force.com/${row.cells[0].data}`, '_blank'));
  }
  catch(error) {
    console.error(error);
  }
}

async function loadCasesGrid(agent_info) {
  try {
    const resp = await axios.post('/api/opportunities', agent_info);
    const ddata = resp.data.records.map(opportunity => [
      opportunity.Id,
      opportunity.Name,
      opportunity.carriers_name__c,
      opportunity.RecordType.Name,
      opportunity.Plan_Name__c,
      opportunity.Policy_Number__c,
      opportunity.EffectiveDate__c,
      opportunity.Annual_Premium__c,
      opportunity.StageName
    ]);

    const grid = new Grid({
      columns: [
        { name: 'Id', hidden: true },
        'Name',
        'Carrier',
        'Type',
        'Plan',
        'Policy Number',
        'Effective Date',
        'Premium',
        'Status'
      ],
      search: true,
      sort: true,
      fixedHeader: true,
      data: ddata,
      pagination: {
        enabled: true,
        limit: 20
      },
      className: {
        th: 'grid-header',
        td: 'grid-cell',
      }
    }).render(document.querySelector('.cases-grid-wrapper'));

    grid.on('rowClick', (evt, row) => window.open(`https://urlinsgroup.lightning.force.com/${row.cells[0].data}`, '_blank'));
  }
  catch (err) {
    console.error(err);
  }
}

async function loadDatafeedGrid() {
  const month = document.querySelector('#month').value;
  const year = document.querySelector('#year').value;
  const special_id = document.querySelector('#special_id').value;
  const carrier_code = document.querySelector('#carrier_code').value;

  try {
    console.log(month, year, special_id, carrier_code);
    const resp = await axios.post('/api/datafeed', {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
      special_id: parseInt(special_id, 0),
      carrier_code: carrier_code
    });

    const feed = resp.data;

    const grid = new Grid({
      columns: [
        { name: 'id', hidden: true },
        'carrier_agent_id',
        'writing_agent_name',
        'agent_name',
        'agent_name_orig',
        'earned_broker_name',
        'client_name',
        'policy_number',
        'group_number',
        'sub_group',
        'effective_date',
        'invoice_date',
        'product',
        'premium',
        'lives',
        'status',
        'paid_to_url',
        'agent_commission',
        'url_override',
        'url_rate',
        'agent_rate',
        'face_amount',
        'renewal',
        'sf_opportunity_id',
        'cycle_year',
        'state',
        'adj_desc',
        'comment',
        'admin_id',
        'billing_period',
        'initial_effective_date',
        'is_orion'
      ],
      search: true,
      sort: true,
      data: feed,
      pagination: {
        enabled: true,
        limit: 20
      },
      className: {
        th: 'grid-header',
        td: 'grid-cell',
      }
    }).render(document.querySelector('.datafeed-grid-wrapper'));
  }
  catch (err) {
    console.error(err);
  }
}

