export class UrlPhotobar extends HTMLElement {
    constructor() {
        super();
        // ShadowDOM
        const shadow = this.attachShadow({ mode: 'open' });
        // Container
        const wrapper = document.createElement('div');
        wrapper.classList.add('url-photobar');
        
        const img = document.createElement('img');
        img.classList.add('img-w-100');
        img.src = this.getAttribute('src');
        img.alt = this.getAttribute('alt');

        wrapper.appendChild(img);

        const style = document.createElement('style');
        style.textContent = `
            .url-photobar {
                width: 100%; 
                border-top: 5px solid rgb(255, 178, 64); 
                border-bottom: 5px solid rgb(255, 178, 64);
            }
            .img-w-100 {
                width: 100%;
                height: auto;
                display: block;
            }
        `;

        shadow.appendChild(style);
        shadow.appendChild(wrapper);
    }
}

customElements.define('url-photobar', UrlPhotobar);